.right-bold{


    text-align: left;
    
    
    
    
    font-size: 40pt;
    font-family: neue-haas-unica,sans-serif;
    color: black;
    font-style: normal;
    font-weight: 700;
    
    width: 50%;
    margin-left: 50%;
    margin-top: 2em;
    margin-bottom: 2em;

    line-height: 4rem;
    
    }


   


@media only screen and (max-width: 900px)
{
    .right-bold
    {
        font-size: 24pt;
        font-weight: 700;
        width: 100%;
        margin-left: 0%;

        margin-top: 3em;
        margin-bottom: 3em;
        line-height: 2rem;
    }
}