
  
  .image-container {
    /*
    flex: 0 0 50%;
    margin-right: 20px;
    */
    overflow: hidden;
    display: flex;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    min-height:576px;
  }


@media only screen and (max-width: 900px)
{
  .image-container img {
    min-height:0px;
  }
 
}

 
  
  