.my-component-container {
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 20px;
    overflow: hidden;

    margin-bottom: 0%;
  }

  .profile-container {
    display: flex;
    align-items: left;
    margin-left: 10%;
    margin-top: 40px;
  }
  

  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #ccc;
    
    overflow: hidden; /* Ensure the image stays within the circular frame */
  }
  
  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and fill the container */
  }

  .hamburger-menu {
    width: 30px;
    height: 24px;
    margin-left: calc(60% - 100px);

 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    /* Add your hamburger menu icon or content styles here */
  }

  .hamburger-menu .line {
    width: 100%;
    height: 2px;
    background-color: #000;
    transition: transform 0.3s, opacity 0.3s;
  }
  
  .hamburger-menu.open .line:nth-child(1) {
    transform: rotate(45deg) translate(7.5px, 7.5px);
  }
  
  .hamburger-menu.open .line:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger-menu.open .line:nth-child(3) {
    transform: rotate(-45deg) translate(7.5px, -7.5px);
  }
  
  
  .text-container {
    
    width: 100%;
    display: flex;
    margin-left: 10%;
    padding-top: 4em; 
    justify-content: left;
    margin-bottom: 10%;
  }
  
  .large-text {
    width: 40%;
    font-size: 64px;
    font-weight: bold;
    font-style: italic;
    flex-direction: column;
    text-align: left;
  }
  
  .description-container {
    width: 30%;
    display: flex;
    padding-top: 3em;
    flex-direction: column;
    justify-content: left;
  }
  
  .small-text {
    font-size: 18px;
    text-align: left;
    line-height: 25px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: rgb(255, 255, 255);
    z-index: 9999;
    overflow: hidden;
    display: block;
    align-items: center;
    justify-content: center;

    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-name: modalOpen;
   
  }
  
  .modal.open {
    animation-name: modalOpen;
  }

  .modal.close {
    animation-name: modalClose;
  }

   @keyframes modalOpen {
    0% {
      height: 0;
      opacity: 0.5;
    }
    100% {
      height: 100vh;
      opacity: 1.0;
    }
  }

  @keyframes modalClose {
    0% {
      height: 100%;
      opacity: 0.0;
    }
    100% {
      height: 0;
      opacity: 0.0;
    }
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
    max-width: 400px;

  }

 



  .overlay {
    position: relative;
    z-index: 99999;
  }


  @media only screen and (max-width: 900px)
{

  .text-container
  {
    display: inline-block;
  }

  .description-container
  {
    width: 80%;
   }

  .large-text {
    width: 100%;
  }



  .small-text {
   width: 100%;
  }
}
  