.contact-container {
    display: flex;

    width: 80%;
    
    margin-left:10%;

    margin-top: 10em;
  }
  
  .connect-container {
    flex: 1;
   
  }
  
  .contact-form-container {
    flex: 1;
    flex-direction: column;
    
    justify-content: left;
  }

.connect-title {
  
    /*
    margin-bottom: 10px;
        
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: left;
    color: black;
    */

    font-size: 64px;
    font-weight: bold;
    font-style: italic;
    flex-direction: column;
    text-align: left;
}

.email-text {
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 4em;
    margin-top: 4em;
}


.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    width: 100%;

    
  }
  
  .input-title {
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
  }
  
  .input-box {
    width: 100%;
    height: 30px;
    border: 1px solid black;
   
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    word-break: break-word;
    
  }

  .send-container
  {
    width: 100%;
    text-align: right;
  }

  .send-button
  {
    font-size: 18px;
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 5px;
    cursor: pointer;

    
  }


@media only screen and (max-width: 1024px)
{

    .contact-container {
       flex-direction: column;
       
      }
}

@media only screen and (max-width: 900px)
{

    .contact-container {
       flex-direction: column;
       
      }
}

