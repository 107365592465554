.thumbnail-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-top: 1em;
  }
  
  .thumbnail-container {
    display: inline-flex;
    white-space: nowrap;

    position: relative;
    left: -50%;
    border-radius: 50%;
    
  }
  
  .thumbnail {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    transition: transform 0.5s ease;
    
    margin-right: 30px;
    margin-left: 30px;
   
    
    
   
  }
  
  .thumbnail-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #ccc;
    
    overflow: hidden; /* Ensure the image stays within the circular frame */
  }
  
  
 .thumbnail img {
    width: 120px;
    height: 120px;
    object-fit: cover; /* Maintain aspect ratio and fill the container */
  }


  
  .thumbnail-title {
   
    font-size: 24px;
    font-weight: bold;
    margin-left: 1.5em;
    color: black;
  }

  .thumb-overlay {
   /*
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    z-index: 2;
    pointer-events: none;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    */

    width: 50%;
    max-width: 900px;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    z-index: 2;
    pointer-events: none;
    -webkit-mask-image: linear-gradient(to right, transparent, white 10%, white 90%, transparent);
    mask-image: linear-gradient(to right, transparent, white 10%, white 90%, transparent);

  }

@media only screen and (max-width: 900px)
{




.thumb-overlay
{
  width: 100%;
  -webkit-mask-image: none;
  mask-image:none;

}

}
  
