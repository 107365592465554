.about-container {
  
    display: flex;
   
    
    margin-left:10%;
    margin-bottom: 50px;

    margin-top: 10rem;

   
}

.about-text-container
{
    width:44%;
    
  

}

.about-image-container
{
    width:30%;
    
   

}


@media only screen and (max-width: 1024px)
{
    .about-container{
      flex-direction: column;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    .about-text-container
    {
        width:100%;
       
    }

    .about-image-container
    {
        width:80%;
       
        margin-top: 2em;
        align-self: center;
       
    }
}

@media only screen and (max-width: 900px)
{
    .about-container{
      flex-direction: column;
      

      
    }

    .about-text-container
    {
        width:100%;
        padding: 0px;
       
    }

    .about-image-container
    {
        width:80%;
       
        margin-top: 2em;
        align-self: center;
       
    }
}