.footer-container {
  position: relative;
  width: 100%;
  display: flex;
  align-content: center;
  margin-left: 10%;
  margin-top: 200px;
  margin-bottom: 50px;
 
}

.column {
  font-size: 14px;
  flex: 1;
  text-align: left;
  font-weight: bold;
  

}

.column-social{
  flex: 1;
  text-align: left;
  flex-direction: column;
}


.footer-text {
  font-size: 14px;
  text-align: left;
  font-weight:lighter;
  margin-bottom: 10px;
  margin-top: 10px;

}

.social-img {
  margin-top: 10px;
  margin-right: 10px;
  width: 40x;
  height: 40px;
  overflow: hidden; 
}


@media only screen and (max-width: 900px)
{

  .footer-container {
  display: inline-block;
  margin-top: 100px;
  }

  .column {
    margin-top: 3rem;
  }

}