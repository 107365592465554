.skills-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    margin-left: 10%;
    width: 80%;
    margin-top: 2em;
  }
  
  .skill-box {
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 10px;
    margin: 5px;
    font-size: 12px;
  }