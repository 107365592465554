.left-paragraph{


text-align: left;

overflow: hidden;


font-size: 18px;

color: black;
font-style: normal;


width: 50%;

margin-top: 2em;
margin-bottom: 2em;

line-height: 25px;

}

@media only screen and (max-width: 900px)
{
    .left-paragraph
    {
        width: 100%;
    }
}