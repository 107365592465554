.project-container {
    display: flex;
   /* align-items: center; */
    width: 100%;
    background-color: #000000;
  }

@media only screen and (max-width: 900px)
{
    .project-container{
      flex-direction: column;
     
    }
}

  .project-link {
   
    cursor: pointer;
    text-decoration: none; /* Remove underlining */
  }