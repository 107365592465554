.cv-key-container {
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-content: left;
    margin-left: 10%;
    margin-top: 10em;
   
}

.key-header-text{
    font-size: 24px;
    flex: 1;
    text-align: left;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 1em;
}


.key-points-text {
    
    font-size: 18px;
    text-align: left;
    font-weight: normal;
    font-style: italic;
    margin-top: 0.5em;
    margin-left: 1%;
    margin-bottom: 0.5em;
  }

  .cv-text-container {
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-content: left;
    margin-left: 10%;
    margin-top: 2em;
    margin-bottom: 2em;
    
    width: 100%;
}

  .text-header-column {
    font-size: 18px;
    flex: 1;
    text-align: left;
    
    
    font-weight: bold;
    font-style: normal;
    margin-top: 2em;
    margin-bottom: 1em;
   
    
  }

  .text-header-content {
    font-size: 14px;
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-style: normal;
    margin-left: 1%;
    margin-top: 1em;


    line-height: 22px;
    width: 79%;
  
  }

  .text-column-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-left: 10%;
    margin-top: 200px;
    margin-bottom: 50px;
  }

  .text-rows-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .text-row {
    display: flex;
    width: 80%;
  }
  

.cv-text-column {
    padding: 10px;
  

    font-size: 14px;
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-style: normal;
    margin-left: 1%;
    margin-top: 0em;
    line-height: 22px;
}

.cv-text-column-bold
{
    font-size: 16px;
    font-weight: bold;
    
}

.cv-text-column:nth-child(1) {
  flex: 1 5%; /* First column width: 20% */
}

.cv-text-column:nth-child(2) {
  flex: 1 80%; /* Second column width: 80% */
}







  .text-skillls-row {
    display: flex;
    width: 80%;
  }
  

.cv-skillls-column {
  padding: 10px;
  

  font-size: 14px;
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-style: normal;
    margin-left: 1%;
    margin-top: 0em;
    line-height: 8px;
}

.cv-skillls-column:nth-child(1) {
  flex: 1 5%; /* First column width: 20% */
}

.cv-skillls-column:nth-child(2) {
  flex: 1 80%; /* Second column width: 80% */
}

