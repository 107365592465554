.heroproject-text-container {

  
    
  background-color: black;
    width: 100%;

    overflow: hidden;
 
    /*
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    margin-left: 20px;
    align-self: flex-start; 
    
    margin: 100px;
    */
   
  }

  

  .subject {

    margin-top: 100px;
    margin-left: 100px;
    margin-right: 100px;

    font-size: 21px;
    margin-bottom: 4px;
    text-align: left;
    color: white;

    margin-bottom: 10px;

    text-decoration: "none";

   
   
  }
  
  .title {

    

    margin-left: 100px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 100px;
   
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: left;
    color: white;

    width: intrinsic;
    
   
  }
  
  .description {


    margin-left: 100px;
    margin-right: 100px;

    font-size: 18px;
    text-align: left;
    color: white;

    line-height: 25px;
    
  }

  .hover-underline-animation {
    /*display: inline-block;*/
    position: relative;
    color: #000000;
  }
  
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }


  @media only screen and (max-width: 900px)
{
    .description{
      margin-left: 10%;
      margin-right: 10%;
      margin-bottom: 4rem;
    }

    .subject
    {
      margin-left: 10%;
      margin-right: 10%;
    }

    .title
    {
      margin-left: 10%;
      margin-right: 10%;
      text-decoration: underline;
  
    }

    .hover-underline-animation:hover::after {
      transform: scaleX(0);
    
    }

}