.menu-time {

    

    margin-left: 100px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 100px;
   
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: left;
    color: white;

    width: intrinsic;
    
   
  }


  .navigation-menu {
    
    background-color: white;
   
 
    margin-left: 40%;
    margin-top: 30%;
   
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: left;
    color: white;

    width: 450px;

  }
  
  .navigation-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .navigation-menu li {
    padding: 20px;
   
  }
  
  .navigation-menu li:last-child {
    border-bottom: none;
  }
  
  .navigation-menu li a {
    color: #000000;
    text-decoration: none;
    font-style: italic;
  }
  
  .navigation-menu li a:hover {
    color: #000;
    font-weight: bold;
  }

  .hover-underline-animation {
    /*display: inline-block;*/
    position: relative;
    color: #000000;
  }
  
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }


  @media only screen and (max-width: 900px)
{
    .navigation-menu
    {
      font-size: 32px;
      margin-left: 0%;
    }
}