.video-container {
    
    justify-content: center;
    align-items: center;
    
    height: 100vh;
    position: relative;
    
   
  }
  
.video {
    
    width: 100%;
    height: auto;
   
  }


  @media only screen and (max-width: 900px)
  {
  
      .video-container {
      
        
          height: 50vh;
         
          
         
        }
  
      
  }


@media only screen and (max-width: 414px)
{

    .video-container {
    
        left:-50%;
        height: 20vh;
       
        
       
      }

    
}
  