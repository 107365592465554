.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.home-portfolio-container
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-portfolio-title {

    
text-align: center;
color:black;
background-color: white;
font-size: 4em;
font-style: italic;
margin-top:60px;
width: 100%;
font-weight: bold;
width: intrinsic;
 
}

.underline-animation {
  /*display: inline-block;*/
  position: relative;
  color: #000000;
}

.underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.app-extra-seperator
{
  width:20%;
  padding-left:20%;
  padding-right:5%;
}

.app-seperator-left
{
  width: 44%;
  padding-left:6%;
  padding-right:5%; 
}

.app-seperator-right
{
  width: 20%;
  padding-left:20%;
  padding-right:5%; 
}




@media only screen and (max-width: 900px)
{
  .app-seperator-left
  {
    width: 100%;
    padding-left:0%;
    
  }




  .app-seperator-right
  {
    width: 100%;
    padding-left:0%;
    
  }

  .underline-animation:hover::after {
    transform: scaleX(0);
  
  }

  .home-portfolio-title {
    text-decoration: underline;
    
  }
}

