.feature-fill {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: calc(100vh - 200px);
    margin-top: 2rem;
    
}

.feature-fill img
{
    width: 100%;
    
}

.project-description-container {
    width: 80%;
    margin-left: 10%;
   
}



.project-client {
  
    font-weight: 300;
    font-style: normal;
    color: black;
    text-align: center;
    font-size: 20px;
    line-height: 4rem;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 4rem;
}


.project-description {
  
        font-weight: 700;
        font-style: normal;
        color: black;
        text-align: left;
        font-size: 40px;
        line-height: 4rem;
        margin-top: 8rem;

      
}

.project-title {
  
    font-weight: 500;
    font-style: normal;
    color: black;
    text-align: center;
    font-size: 4rem;
    line-height: 4rem;
    margin-top: 2rem;
    margin-bottom: 0em;

    margin-top: 10rem;
}

.project-content {
    float: left;
    position: relative;
    margin: 1.5em;
    
    width: 80%;
    margin-left: 10%;
    overflow: hidden;


    font-size: 18px;
  
    color: black;
    font-style: normal;
 
}


.projects-button {
    
    display: flex;
    align-items: center;
    
    font-size: 18px;
    background-color: white;
    color: black;
    border: 1px solid black;
   /* padding: 10px 20px; */

   margin-top: 2rem;

    width: 8rem;
    height: 8rem;
   
    cursor: pointer;
    text-decoration: none;
   
    float: left;
   
  }
  

  .button-container {
    display: flex;
    justify-content: space-between;
  }
  
  .button-container button {
    flex: 1;
    padding: 10px;
  }
  
  .button-container .left-button {
    justify-content: flex-start;
  }
  
  .button-container .right-button {
    justify-content: flex-end;
  }
  


@media only screen and (max-width: 900px)
{
    .feature-fill
    {
        height: 100%;
    }

    .project-description-container {
        width: 80%;
        margin-left: 10%;
       
    }

    .project-description {
        /*   font-family: neue-haas-unica,sans-serif;*/
          
        
           line-height: 3rem;
 
   
         
   }

    .project-description {
        width: 100%;
        margin-left: 0%;
    }
   

    .project-content {
        width: 100%;
        margin-left: 0%;
    }

 
}

