
.portfolio-text-container {
    position: relative;
    width: 80%;
    display: flex;
    align-content: left;
    margin-left: 0%;
    margin-top: 200px;
    margin-bottom: 50px;
  }
  
  .text-column {
    font-size: 24px;
    flex: 1;
    text-align: left;
    font-weight: bold;
    font-style: italic;
  
  }

  .portfolio-heading-text {
    font-size: 18px;
    text-align: left;
    font-weight: normal;
    font-style: italic;
  }

  .form-outline
  {
    border: 1px solid black; 
    padding: 40px;
  }

  .form-container {
   
    padding: 5px;
    outline: none;

    display: flex;
    align-items: flex-start;

    margin-top: 2em;
  }

  .form-input {
    font-size: 18px;
    border: 1px solid black;
    padding: 5px;
    outline: none;
    padding: 10px 20px;
    
  }
  
  .form-button {
    font-size: 18px;
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 5px;
    cursor: pointer;
  }



.portfolio-container {
  
    width: 80%;
    
    margin-left:10%;
    margin-bottom: 50px;
    
}

.featured-project-container
{
  
}

.featured-project {
    position: relative;
    width: 100%;
    min-height: 320px;
    margin-top: 10rem;
  
}

.featured-image{
    
    width: 100%;
    
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 55%);
}




.featured-text-container {
    position: relative;
    width: 75%;
    top: 0;
    left: 0;
    margin-top: 6rem;
    margin-left: 0rem;
    padding-bottom: 1rem;
   /*
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    margin-top: 6rem;
    margin-left: 6rem;
    */
    
}

.is-visible {
    transform: translateY(0em);
    opacity: 1;
}

.featured-text-title {
    position: relative;
    color: rgb(0, 0, 0);
    font-family: neue-haas-unica,sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 3rem;
    line-height: 5rem;
    /*text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);*/
    text-align: left;
    text-decoration: none;
}

.featured-text-description {
    position: relative;
    font-family: ff-tisa-web-pro,serif;
    font-weight: 350;
    font-style: normal;
    color: black;
    text-align: left;
    margin-bottom: 1rem; 
    font-size: 18px;
   /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.65); */
}

.featured-text-container.is-visible {
    transform: translateY(0em);
    opacity: 1;
}



@media only screen and (max-width: 1024px)
{
    .text-column{
      font-size: 12px;
      text-align: center;
    }

   .form-button{
    margin-top: 1em;
    width: 100%;
   }
   .form-input
   {
    margin-top: 1em;
   }

   .form-container {
   
   
    display: inline;
   

    margin-top: 1em;
  }
  

   .featured-text-container {
   
    margin-top: 1rem;
    width: 80%;

    
    color: black;

    padding-bottom: 0.5rem;
    
   }

   

   .featured-text-description{
    margin-top: 1rem;
   }

   .gradient-overlay {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%);
   }
}


@media only screen and (max-width: 900px)
{
    
  .portfolio-text-container {
    width: 100%;
  }
  
  .form-outline
  {
    margin-bottom: 8rem;
  }
  .featured-project{
    margin-top: 6rem;
  }


  .form-input
  {
    width: 80%;
  }

  .featured-text-container
  {
      position: relative;
      margin-top: 4%;
      margin-left: 0%;
      width: 80%;
     /* text-decoration: underline; */
  }

  .gradient-overlay {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%);
   }

  .featured-text-description{color: black;font-size: 14px;text-shadow: 0 2px 2px rgba(0, 0, 0, 0.0);}
  .featured-text-case{color: black;text-shadow: 0 2px 2px rgba(0, 0, 0, 0.0);}
  .featured-text-title{color: black;font-size: 2rem;text-shadow: 0 2px 2px rgba(0, 0, 0, 0.0); line-height: 2.5rem;}
}






    