.full-paragraph{


    text-align: left;
    
    overflow: hidden;
    
    
    font-size: 18px;
    
    color: black;
    font-style: normal;
    
    
    width: 100%;
    
    margin-top: 2em;
    margin-bottom: 2em;

    line-height: 25px;
    
 }