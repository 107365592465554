.futures-component {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 10rem;
  
}

.futures-container {
  width: 80%;
  left: 10%;
  position: relative;
}

.futures-title {
  font-size: 72px;
  font-weight: bold;
  text-align: left;
  color: black;
}

.futures-description {
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  color: rgb(19, 52, 97);

 
}



.phase-background {
  width: 100%;
  height: 100%;
  
  position: relative;
  min-height: 400px;

  padding-top: 2rem;
  padding-bottom: 2rem;
}

.phase-content-container {
  display: flex;
  width: 100%;
  min-height: 400px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 6rem;
  margin-bottom: 6rem;
}

.phase-object-container {
  display: flex;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.object-container {
  display: flex;
  justify-content: center;
  align-items: center;
 
  width: 100%;
  /*transform: scale(0.75);*/

  min-width: 700px;
  min-height: 700px;
 

  margin-top: 2rem;
  margin-bottom: 2rem;
}

.phase-text-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.phase-text-title
{
 
  font-size: 48px;
  font-weight: bold;
  text-align: left;
  color: rgb(19, 52, 97);

  margin-top: 4rem;
  margin-bottom: 4rem;
}

.phase-text-description
{
  font-size: 18px;
  line-height: 2rem;
  font-weight: normal;
  text-align: center;
  color: rgb(19, 52, 97);

  margin-top: 2rem;
  margin-bottom: 4rem;

  width: 80%;
}

.svg-object {
  position: relative;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 900px)
{
  .object-container {

  
    min-width: 300px;
    min-height: 300px;

  }
}

